<template>
  <div class="login_animate">
    <div class="star_six star1"></div>
    <div class="star_six star2"></div>
    <div class="star_six star3"></div>
    <div class="star_six star4"></div>
    <div class="star_six star5"></div>
    <div class="star_six star6"></div>
    <div class="star_six star7"></div>
    <div class="star_six star8"></div>
    <div class="star_six star9"></div>
    <div class="star_four star1"></div>
    <div class="star_four star2"></div>
    <div class="star_four star3"></div>
    <div class="star_four star4"></div>
    <div class="star_four star5"></div>
    <div class="star_four star6"></div>
    <div class="star_four star7"></div>
    <div class="star_four star8"></div>
    <div class="star_four star9"></div>
    <div class="star_four star10"></div>
    <div class="meteor meteor1" :style="meteorStyle"></div>
    <div class="meteor meteor2" :style="meteorStyle"></div>
    <div class="meteor meteor3" :style="meteorStyle"></div>
  </div>
</template>

<script>

export default {
  name: 'loginAnimate',
  computed: {
    meteorStyle() {
      const clientWidth = document.documentElement.clientWidth
      const clientHeight = document.documentElement.clientHeight
      const meteorRadians = Math.atan(clientHeight / clientWidth);
      const meteorDegrees = 90 - meteorRadians * 180 / Math.PI;
      return {
        '--rotate': `rotate(${meteorDegrees}deg)`
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.login_animate {
  min-height: 100vh;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  @include screen-mobile {
    display: none;
  }

  .star_six {
    width: 90px;
    height: 90px;
    background: url('~@/assets/images-1/login/star02.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    animation: star_six 1.5s infinite;
  }

  .star_six.star1 {
    top: 6%;
    right: 13%;
  }

  .star_six.star2 {
    bottom: 30%;
    left: 3%;
    width: 40px;
    height: 40px;
    animation-delay: 0.5s;
  }

  .star_six.star3 {
    top: 45%;
    right: 1%;
    width: 40px;
    height: 40px;
  }

  .star_six.star4 {
    top: 30%;
    left: 8%;
    width: 80px;
    height: 80px;
    animation-delay: 0.5s;
  }

  .star_six.star5 {
    bottom: 30%;
    right: 15%;
    width: 80px;
    height: 80px;
  }

  .star_six.star6 {
    bottom: 3%;
    left: 29%;
    width: 80px;
    height: 80px;
    animation-delay: 0.5s;
  }

  .star_six.star7 {
    top: 40%;
    left: 20%;
    width: 40px;
    height: 40px;
  }

  .star_six.star8 {
    top: 2%;
    right: 30%;
    width: 40px;
    height: 40px;
  }

  .star_six.star9 {
    top: 24%;
    right: 20%;
    width: 40px;
    height: 40px;
    animation-delay: 0.5s;
  }

  .star_four {
    width: 100px;
    height: 100px;
    background: url('~@/assets/images-1/login/star03.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    animation: star_four 1.5s infinite;
  }

  .star_four.star1 {
    top: 3%;
    right: 55%;
  }

  .star_four.star2 {
    bottom: 10%;
    left: 10%;
  }

  .star_four.star3 {
    top: 30%;
    right: 10%;
    width: 60px;
    height: 60px;
  }

  .star_four.star4 {
    top: 20%;
    left: 2%;
    width: 60px;
    height: 60px;
    animation-delay: 0.5s;
  }

  .star_four.star5 {
    bottom: 15%;
    right: 10%;
    width: 60px;
    height: 60px;
  }

  .star_four.star6 {
    top: 5%;
    left: 5%;
    width: 40px;
    height: 40px;
    animation-delay: 0.5s;
  }

  .star_four.star7 {
    top: 12%;
    left: 24%;
    width: 40px;
    height: 40px;
    animation-delay: 0.5s;
  }

  .star_four.star8 {
    top: 55%;
    right: 8%;
    width: 40px;
    height: 40px;
  }

  .star_four.star9 {
    top: 10%;
    right: 5%;
    width: 40px;
    height: 40px;
    animation-delay: 0.5s;
  }

  .star_four.star10 {
    bottom: 0%;
    right: 35%;
    width: 80px;
    height: 80px;
  }

  .meteor {
    background: url('~@/assets/images-1/login/star01.png') no-repeat;
    background-size: 100% 100%;
    position: absolute;
  }

  .meteor1 {
    width: 26px;
    height: 173px;
    top: 0%;
    right: 0%;
    animation: meteor1 1.5s ease-in infinite;
    animation-delay: 0s;
  }

  .meteor2 {
    width: 21px;
    height: 138px;
    top: 40%;
    right: -10%;
    animation: meteor2 1.5s ease-in infinite;
    animation-delay: 0.5s;
  }

  .meteor3 {
    width: 21px;
    height: 138px;
    top: -20%;
    right: 40%;
    animation: meteor3 1.2s ease-in infinite;
    animation-delay: 1.5s
  }

  @keyframes star_six {
    0% {
      opacity: 0;
      -webkit-transform: rotate(0deg);
    }

    50% {
      opacity: 1;
      -webkit-transform: rotate(-75deg);
    }

    100% {
      opacity: 0;
      -webkit-transform: rotate(-150deg);
    }
  }

  @-webkit-keyframes star_six {
    0% {
      opacity: 0;
      -webkit-transform: rotate(0deg);
    }

    50% {
      opacity: 1;
      -webkit-transform: rotate(-75deg);
    }

    100% {
      opacity: 0;
      -webkit-transform: rotate(-150deg);
    }
  }

  @keyframes star_four {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @-webkit-keyframes star_four {
    0% {
      opacity: 0;
    }

    50% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }

  @keyframes meteor1 {
    0% {
      transform: var(--rotate);
      opacity: 1;
      top: 0%;
      right: 0%;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: var(--rotate);
      opacity: 1;
      top: 100%;
      right: 100%;
      opacity: 0;
    }
  }

  @-webkit-keyframes meteor1 {
    0% {
      transform: var(--rotate);
      opacity: 1;
      top: 0%;
      right: 0%;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: var(--rotate);
      opacity: 1;
      top: 100%;
      right: 100%;
      opacity: 0;
    }
  }

  @keyframes meteor2 {
    0% {
      transform: var(--rotate);
      opacity: 1;
      top: 40%;
      right: -10%;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: var(--rotate);
      opacity: 1;
      top: 100%;
      right: 50%;
    }
  }

  @-webkit-keyframes meteor2 {
    0% {
      transform: var(--rotate);
      opacity: 1;
      top: 40%;
      right: -10%;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: var(--rotate);
      opacity: 1;
      top: 100%;
      right: 50%;
    }
  }

  @keyframes meteor3 {
    0% {
      transform: var(--rotate);
      opacity: 1;
      top: -20%;
      right: 40%;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: var(--rotate);
      opacity: 1;
      top: 40%;
      right: 100%;
    }
  }

  @-webkit-keyframes meteor3 {
    0% {
      transform: var(--rotate);
      opacity: 1;
      top: -20%;
      right: 40%;
    }

    90% {
      opacity: 1;
    }

    100% {
      transform: var(--rotate);
      opacity: 1;
      top: 40%;
      right: 100%;
    }
  }
}
</style>
