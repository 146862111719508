<template>
  <div class="login_wrapper">
    <div class="login_top">
      <div class="logo"></div>
      <Language class="login_lang" source="login"></Language>
    </div>
    <div class="login_container">
      <div class="container">
        <div class="aside">
          <div class="logo"></div>
        </div>
        <div class="content">
          <transition>
            <router-view></router-view>
          </transition>
        </div>
      </div>
    </div>
    <LoginAnimate></LoginAnimate>
  </div>
</template>

<script>
import LoginAnimate from '@/views/LoginAnimate.vue'
import Language from '@/components/template/header/Language';

export default {
  name: 'loginLayout',
  components: { LoginAnimate, Language },
  data() {
    return {
      reRender: 0,
    };
  },
  computed: {
    showSocialMedia() {
      return this.$config.showSocialMedia(this.regulator)
    },
  },
};
</script>

<style lang="scss">
.login_wrapper {
  position: relative;
  width: 100%;
  min-height: 100vh;
  background: url('~@/assets/images-1/login/background.png') center no-repeat;
  background-size: cover;
  overflow: hidden;

  .login_top {
    position: relative;

    .logo {
      position: absolute;
      width: 0;
      height: 49px;
      background: url('~@/assets/images-1/login/logo.png') center no-repeat;

      @include screen-mobile {
        width: 160px;
        top: 16px;
        @include rtl-sass-prop(left, right, 28px);
      }
    }

    .login_lang {
      position: absolute;
      top: 28px;
      @include rtl-sass-prop(right, left, 40px);
      display: flex;
      cursor: pointer;
      z-index: 3;

      svg {
        color: $white;
      }

      @include screen-mobile {
        top: 16px;
      }
    }
  }

  .login_container {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px;
    position: relative;
    z-index: 2;

    @include screen-mobile {
      min-height: 720px;
      overflow: scroll;
    }

    @include screen-mobile {
      width: 100%;
    }

    .container {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 600px;
      overflow: hidden;

      @include screen-mobile {
        height: 520px;
      }

      .aside {
        position: relative;
        width: 340px;
        background: url('~@/assets/images-1/login/aside.png') center no-repeat;
        @include rtl-sass-prop-dual(border-radius, 16px 0 0 16px, border-radius, 0 16px 16px 0);


        @include screen-mobile {
          width: 0;
        }

        .logo {
          width: 160px;
          height: 49px;
          background: url('~@/assets/images-1/login/logo.png') center no-repeat;
          position: absolute;
          top: 140px;
          left: 50%;
          transform: translateX(-50%);
        }
      }

      .content {
        position: relative;
        width: 560px;
        padding: 80px 80px 0 80px;
        background-color: $white;
        @include rtl-sass-prop-dual(border-radius, 0 16px 16px 0, border-radius, 16px 0 0 16px);

        @include screen-mobile {
          width: 100%;
          padding: 24px 16px 0 16px;
          @include rtl-sass-prop-dual(border-radius, 16px, border-radius, 16px);
        }
      }
    }
  }
}
</style>
